<template>
   <div class="emb-cart-wrap emb-card mt-10">
      <!-- <emb-page-title
         heading="Here's what's in your bag"
         subHeading="Our latest news and learning articles."
      >
      </emb-page-title> -->
      <div class="cart-content section-gap">
         <v-container grid-list-xl py-0>
            <div  v-if="!cart" class="text-center">
               <div class="text-center">
                  <div class="mb-6">
                     <img  alt="cart-empty" height="128" src="static/images/empty-cart.png" width="128">
                  </div>
                  <h4 > Tu carrito de compras está vacía</h4>
                  <v-btn color="secondary" :to="{name: 'menu'}">Ir a la carta</v-btn>
               </div>
            </div>
            <div v-else class="cart-shop-list">
               <div class="emb-card mb-12 pa-6 white">
                  <v-simple-table dense>
                     <template v-slot:default>
                        <thead>
                           <tr>
                              <!-- <th class="text-left">Imagen</th> -->
                              <th class="text-left">Producto</th>
                              <th class="text-right">Cantidad</th>
                              <th class="text-right">Precio Unit.</th>
                              <th class="text-right">Total</th>
                              <th class="text-right">Nota</th>
                              <th class="text-right">Eliminar</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr v-for="(item, index) in cart" :key="index">
                              <!-- <td>
                                 <img :src="item.item.image" alt='cart-item' width="100">
                              </td> -->
                              <td>{{ item.item.name }}</td>
                              <td class="text-right align-middle">
                                 <v-text-field
                                    class="p-0"
                                    type="number" 
                                    v-model="item.quantity" 
                                    min="1" max="10" 
                                    outlined dense
                                    @input="calculateItemTotal(index)"
                                 >
                                 </v-text-field>
                                 <!-- <el-input-number
                                    v-model="item.quantity" 
                                    :min="1" :max="10"
                                    @input="calculateItemTotal(index)"
                                    controls-position="right"
                                    :step="0"
                                 >
                                 </el-input-number> -->
                              </td>
                              <td class="text-right"><emb-currency-sign></emb-currency-sign> {{ item.unit_price }}</td>
                              <td class="text-right"><emb-currency-sign></emb-currency-sign> {{ item.total }}</td>
                              <td class="text-right p-0">
                                 <v-text-field
                                    class="p-0"
                                    type="text" 
                                    v-model="item.note" 
                                    outlined dense
                                 >
                                 </v-text-field>
                              </td>
                              <td class="text-right">
                                 <a class="accent--text remove-cart" @click="deleteProductFromCart(cart)" href="javascript:void(0)">
                                    <i class="material-icons font-weight-bold">close </i>
                                 </a>
                              </td>
                           </tr>
                        </tbody>
                     </template>
                  </v-simple-table>
               </div>
               <v-layout align-center justify-end>
                  <v-flex xs12 sm12 md12 lg8 xl7 py-0>
                     <v-card>
                        <v-card-text>
                           
                           <!-- <div class="layout align-center justify-space-between subtotal">
                              <p>Subtotal</p>
                              <span>
                                 S/
                                 {{itemTotal}}
                              </span>
                           </div> -->
                           <div class="layout align-center justify-space-between subtotal">
                              <p>Envío</p>
                              <span>
                                 <emb-currency-sign></emb-currency-sign>
                                 {{shipping}}
                              </span>
                           </div>
                           <!-- <div class="layout align-center justify-space-between subtotal">
                              <p>Tax(GST)</p>
                              <span>
                                 S/
                                 {{tax}}
                              </span>
                           </div> -->
                           <v-divider class="my-3"></v-divider>
                           <div class="layout align-center justify-space-between subtotal layout-gap">
                              <h3>Total</h3>
                              <h3>
                                 <emb-currency-sign></emb-currency-sign>
                                 {{orderData.total}}
                              </h3>
                           </div>
                           <!-- <v-divider></v-divider>
                           <div class="layout align-center justify-space-between subtotal layout-gap">
                              <p class="mt-4">Tipo de entrega</p>
                              <v-radio-group v-model="orderData.type_of_delivery" row>
                                 <v-radio label="Delivery" value="delivery"></v-radio>
                                 <v-radio label="Recojo en tienda" value="in_store"></v-radio>
                              </v-radio-group>
                           </div> -->
                           <div class="layout align-center justify-space-between subtotal layout-gap">
                              <v-btn class="mx-2" color="primary" :to="{name: 'menu'}">
                                 <v-icon left dark>keyboard_arrow_left</v-icon>
                                 Seguir comprando
                              </v-btn>
                              
                              <v-btn class="mr-0" color="primary" @click.prevent="payment()">
                                 Continuar con el pago
                                 <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                              </v-btn>
                           </div>
                        </v-card-text>
                     </v-card>
                  </v-flex>
               </v-layout>
               <emb-delete-confirmation
                  ref="deleteConfirmationDialog"
                  message="¿Eliminar?"
                  @onConfirm="onDeleteProductFromCart"
                  >
               </emb-delete-confirmation>
            </div>
         </v-container>
      </div>
   </div>
</template>
<script>
   import { mapActions, mapGetters } from "vuex";
   import {functions, exchangeRate} from 'Mixins/functions'
   import round from 'lodash/round';

   export default {
      data() {
         return {
            selectDeletedProduct: null,
         };
      },
      mixins: [functions, exchangeRate],
      computed: {
         ...mapGetters(['cart', 'shipping', 'orderData', 'isVerified', 'loggedIn', 'location']),
      },
      created() {
         this.calculateTotal()
      },
      methods: {
         ...mapActions({
            addNotification: 'addNotification',
            changeQuantityHandler: 'changeQuantityHandler',
         }),
         calculateItemTotal(index) {
            let item = this.cart[index]
            let total = round(item.unit_price * item.quantity, 2);

            this.cart[index].total = total

            this.changeQuantityHandler(item)
            this.calculateTotal()
         },
         calculateTotal() {
            let total = 0;

            if(this.cart) {
               for (const item of this.cart) {
                  total += round(item.unit_price * item.quantity, 2);
               }
            }

            this.orderData.total = total
         },
         deleteProductFromCart(product) {
            this.$refs.deleteConfirmationDialog.openDialog();
            this.selectDeletedProduct = product;
         },
         onDeleteProductFromCart() {
            this.$refs.deleteConfirmationDialog.close();
            this.addNotification({
               show: true,
               type: 'error',
               message: 'El producto ha sido eliminado del carrito'
            }).then(() => {
               this.calculateTotal()
            })

            this.$store.dispatch(
               "onDeleteProductFromCart",
               this.selectDeletedProduct
            );
         },
         payment() {
            if (this.loggedIn) {
               this.$router.push({name: 'payment'})
            } else {
               this.$router.push({name: 'login'})  
            }
         }
      }
   };
</script>

